<template>
  <div class="pda-report">
    <div class="head">
      <div class="rtit" style="padding-right: 20px; font-size: 0.2rem">
        {{
          `${$store.state.schoolInfo.atteSchool.schoolName}-${$route.query.stuReport}-${$route.query.class}【${$route.query.grade}】- ${$route.query.stu}`
        }}
        -系统平台班级成长测评报告
        <label for="" style="float: right"
          >最近测试时间:
          {{ tzList.length ? tzList[0].createtime : "--" }}</label
        >
      </div>
      <div class="cont">
        学员测评报告主要来自于全国足球特色幼儿园系统平台的专业测评。我们针对幼儿园阶段不同年级的孩子提供标准化的测试项目。测评项目分体质测试和运动测试两大模块。
      </div>
    </div>
    <div v-loading="loading">
      <div class="testdiv">
        <div class="basic-test">
          <div class="rtit2"><span>体质测试</span></div>
          <div class="testbox">
            <img src="../../../assets/img/pda/tsc.png" class="center" />
            <div class="row">
              <img src="../../../assets/img/pda/ts1.png" class="bg" />
              <div class="item left">
                <b>{{
                  tzList[0].testingFoundation.title +
                  "：" +
                  (tzList[0].reportOne ? tzList[0].reportOne : "0") +
                  handleDw(tzList[0].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[0].avgVal ||
                    "0" + handleDw(tzList[0].testingFoundation.title)
                  }}</span
                >
              </div>
              <div class="item right">
                <b>{{
                  tzList[1].testingFoundation.title +
                  "：" +
                  (tzList[1].reportOne ? tzList[1].reportOne : "0") +
                  handleDw(tzList[1].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[1].avgVal ||
                    "0" + handleDw(tzList[1].testingFoundation.title)
                  }}</span
                >
              </div>
            </div>
            <div class="row">
              <img src="../../../assets/img/pda/ts2.png" class="bg" />
              <div class="item left">
                <b>{{
                  tzList[2].testingFoundation.title +
                  "：" +
                  (tzList[2].reportOne ? tzList[2].reportOne : "0") +
                  handleDw(tzList[2].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[2].avgVal ||
                    "0" + handleDw(tzList[2].testingFoundation.title)
                  }}</span
                >
              </div>
              <div class="item right">
                <b>{{
                  tzList[3].testingFoundation.title +
                  "：" +
                  (tzList[3].reportOne ? tzList[3].reportOne : "0") +
                  handleDw(tzList[3].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[3].avgVal ||
                    "0" + handleDw(tzList[3].testingFoundation.title)
                  }}</span
                >
              </div>
            </div>
            <div class="row">
              <img src="../../../assets/img/pda/ts3.png" class="bg" />
              <div class="item left">
                <b>{{
                  tzList[4].testingFoundation.title +
                  "：" +
                  (tzList[4].reportOne ? tzList[4].reportOne : "0") +
                  handleDw(tzList[4].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[4].avgVal ||
                    "0" + handleDw(tzList[4].testingFoundation.title)
                  }}</span
                >
              </div>
              <div class="item right">
                <b>{{
                  tzList[5].testingFoundation.title +
                  "：" +
                  (tzList[5].reportOne ? tzList[5].reportOne : "0") +
                  handleDw(tzList[5].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[5].avgVal ||
                    "0" + handleDw(tzList[5].testingFoundation.title)
                  }}</span
                >
              </div>
            </div>
            <div class="row">
              <img src="../../../assets/img/pda/ts4.png" class="bg" />
              <div class="item left">
                <b>{{
                  tzList[6].testingFoundation.title +
                  "：" +
                  (tzList[6].reportOne ? tzList[6].reportOne : "0") +
                  handleDw(tzList[6].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[6].avgVal ||
                    "0" + handleDw(tzList[6].testingFoundation.title)
                  }}</span
                >
              </div>
              <div class="item right">
                <b>{{
                  tzList[7].testingFoundation.title +
                  "：" +
                  (tzList[7].reportOne ? tzList[7].reportOne : "0") +
                  handleDw(tzList[7].testingFoundation.title)
                }}</b>
                <span
                  >全国平均值：{{
                    tzList[7].avgVal ||
                    "0" + handleDw(tzList[7].testingFoundation.title)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="sport-test">
          <div class="rtit2"><span>运动测试</span></div>
          <div class="left">
            <div
              class="item"
              v-show="(index + 1) % 2 == 1"
              v-for="(item, index) in ydList"
              :key="index"
            >
              <div class="tit">
                {{ item.testingLog.testingSports.name }}：{{
                  item.value || "-未测试-"
                }}{{
                  `${
                    item.value
                      ? "(" +
                        item.testingLog.testingSports.unit.substr(-1, 1) +
                        ")"
                      : ""
                  }`
                }}
              </div>
              <div
                class="color"
                :class="{
                  step1: item.testingLog.avgVal * 100 < 21,
                  step2: item.testingLog.avgVal * 100 > 60,
                  step3:
                    item.testingLog.avgVal * 100 < 60 &&
                    item.testingLog.avgVal * 100 > 21,
                }"
                :style="{ width: item.testingLog.avgVal * 100 + '%' }"
              ></div>
              <div
                class="text"
                :class="{
                  step1: item.testingLog.avgVal * 100 < 21,
                  step2: item.testingLog.avgVal * 100 > 60,
                  step3:
                    item.testingLog.avgVal * 100 < 60 &&
                    item.testingLog.avgVal * 100 > 21,
                }"
                :style="{ left: item.testingLog.avgVal * 100 + '%' }"
              >
                {{
                  item.testingLog.avgVal * 100
                    ? "超过全国" + item.testingLog.avgVal * 100 + "%"
                    : ""
                }}
              </div>
              <div class="line"></div>
              <div class="probar">
                <span class="i1"></span>
                <span class="i2"></span>
                <span class="i3"></span>
                <span class="i4"></span>
                <div class="t1">0</div>
                <div class="t2">20</div>
                <div class="t3">60</div>
                <div class="t4">100</div>
              </div>
            </div>
          </div>

          <div class="right">
            <div
              class="item"
              v-for="(item, index) in ydList"
              v-show="(index + 1) % 2 == 0"
              :key="index"
            >
              <div class="tit">
                {{ item.testingLog.testingSports.name }}：{{
                  item.value || "-未测试-"
                }}{{
                  `${
                    item.value
                      ? "(" +
                        item.testingLog.testingSports.unit.substr(-1, 1) +
                        ")"
                      : ""
                  }`
                }}
              </div>
              <div
                class="color"
                :class="{
                  step1: item.testingLog.avgVal * 100 < 21,
                  step2: item.testingLog.avgVal * 100 > 60,
                  step3:
                    item.testingLog.avgVal * 100 < 60 &&
                    item.testingLog.avgVal * 100 > 21,
                }"
                :style="{ width: item.testingLog.avgVal * 100 + '%' }"
              ></div>
              <div
                class="text"
                :class="{
                  step1: item.testingLog.avgVal * 100 < 21,
                  step2: item.testingLog.avgVal * 100 > 60,
                  step3:
                    item.testingLog.avgVal * 100 < 60 &&
                    item.testingLog.avgVal * 100 > 21,
                }"
                :style="{ left: item.testingLog.avgVal * 100 + '%' }"
              >
                {{
                  item.testingLog.avgVal * 100
                    ? "超过全国" + item.testingLog.avgVal * 100 + "%"
                    : ""
                }}
              </div>
              <div class="line"></div>
              <div class="probar">
                <span class="i1"></span>
                <span class="i2"></span>
                <span class="i3"></span>
                <span class="i4"></span>
                <div class="t1">0</div>
                <div class="t2">20</div>
                <div class="t3">60</div>
                <div class="t4">100</div>
              </div>
            </div>
            <div class="item tscate">
              <div class="tscell"><i class="c1"></i>需努力</div>
              <div class="tscell"><i class="c2"></i>良好</div>
              <div class="tscell"><i class="c3"></i>优秀</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="rtit">系统平台幼儿运动处方</div>
      <div class="cont">
        学员运动处方来源于系统平台根据国内外对儿童青少年体质健康的前沿研究，根据PDA测试结果，根据体育活动的目的，用处方的形式，制定的系统化、有针对性的体育活动指导方案。
      </div>
    </div>
    <div class="head">
      <div class="rtit2"><span>体质测试</span></div>
      <table class="layui-table style-2" v-loading="loading">
        <thead>
          <tr>
            <th v-for="(item, index) in tzList" :key="index">
              {{ item.testingFoundation.title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in tzList" :key="index">
              {{ item.reportOne || 0 }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <!-- <tr>
            <td>{{tzList.length ? tzList[0].createtime : '--'}}</td>
            <td><span class="up">+{$row['promote']}</span></td>
            <td><span class="down">-{$row['promote']}</span></td>
          </tr> -->
        </tfoot>
      </table>
    </div>
    <div class="head">
      <div class="rtit2"><span>运动处方</span></div>
      <table class="layui-table style-3">
        <thead>
          <tr>
            <th style="width: 300px">主题</th>
            <th style="width: 300px">成绩</th>
            <th>处方建议</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in ydList" :key="index">
            <td>
              <b>{{ item.testingLog.testingSports.title + "能力" }}</b>
            </td>
            <td>
              <span>{{ item.value }}</span>
            </td>
            <td>
              <b>{{ item.testingLog.testingSports.advice || "pda_report2" }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="rtips">
      <b>注意事项：</b>
      <div>
        1、由于体温调节系统发育不成熟，儿童应避免在炎热潮湿的环境下持续运动，并且应注意补水。
      </div>
      <div>
        2、超重或体力不足的儿童应以中等强度体力活动开始，适应后逐渐增加运动频率和时间以达到明天60分钟的目标。
      </div>
      <div>
        3、对于有疾病或特殊问题的儿童，如哮喘、肥胖者，应根据他们的身体状态、症状以及体适能水平制定运动处方。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pda_report_page2",
  components: {},
  data() {
    return {
      tzList: [
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
        { testingFoundation: {} },
      ],
      ydList: [
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
        { testingLog: { testingSports: { name: "" } } },
      ],
      loading: true,
    };
  },
  created() {},
  mounted() {
    this.getTZ();
    this.getYD();
  },
  methods: {
    //   单位转化
    handleDw(val) {
      if (val) {
        let str = "";
        if (val.indexOf("/") != -1) {
          str = val.split("/")[1];
        }
        let res = "";
        if (str) {
          let temp = str.split("");
          temp.unshift("/");
          res = temp.join("");
        }
        return res == "视力" ? "" : res;
      }
    },

    // 体质测试
    getTZ() {
      let data = {
        semesterId: this.$route.query.semesterId,
        stuId: this.$route.query.stuId,
        isEnd: this.$route.query.isEnd,
        gradeType: this.$route.query.gradeType,
        type: this.$route.query.type,
      };
      this.api.pes.findReportByStuId(data).then((res) => {
        if (res.flag) {
          this.tzList = res.data;
          this.loading = false;
          this.tzList.forEach((item) => {
            item.createtime = this.common.dateFormat(
              item.createtime * 1000,
              "yyyy-MM-dd"
            );
          });
        }
      });
    },
    // 运动测试
    getYD() {
      let data = {
        semesterId: this.$route.query.semesterId,
        stuId: this.$route.query.stuId,
        isEnd: this.$route.query.isEnd,
        type: this.$route.query.type,
        gradeType: this.$route.query.gradeType,
      };
      this.api.pes.findReport2ByStuId(data).then((res) => {
        this.ydList = res.data;
        console.log("this.ydList", this.ydList[0].testingLog.avgVal);
        console.log("this.ydList", this.ydList[6]);
        this.ydList.forEach((item) => {
          item.createtime = this.common.dateFormat(
            item.createtime * 1000,
            "yyyy-MM-dd"
          );
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pda_report_page2 {
  width: 100%;
}
</style>